@import "~utils/variables";

.wrapper {
  display: flex;
  justify-content: center;
  padding-top: 10em;
  background-color: rgba(1, 1, 1, 0.1);
  height: 100vh;

  form {
    width: 25em;
    background-color: white;
    height: 21em;
    padding: 1.5em;
    border-radius: 5px;

    h1 {
      margin-bottom: 2em;
    }

    p {
      font-size: 0.9rem;
      color: red;
    }

    div.row {
      width: 100%;
      margin-bottom: 1em;

      label {
        display: inline-block;
        margin-bottom: 0.5em;
      }

      input {
        width: 100%;
        height: 35px;
        border: 1px solid rgba(1, 1, 1, 0.2);
        border-radius: 5px;
        padding-left: 1em;
      }

      button {
        background-color: $primaryColor;
        width: 100%;
        height: 35px;
        color: white;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        margin-top: 1 em;
      }
    }
  }
}
