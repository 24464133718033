$headerHeight: 3em;
$infobarHeight: 2em;
$calcbarHeight: 3em;
$sectionbarHeight: 4em;
$quesTypeHeight: 2em;
$quesNoHeight: 1.8em;
$quesAnsFooterHeight: 4em;
$statusbarHeight: 3em;

$primaryColor: #1e81b0;
